import { themeGet } from '@styled-system/theme-get';
import styled from 'styled-components';
import { device } from '../../../theme';

export const SectionWrap = styled.section`
  padding-top: 60px;
  padding-bottom: 60px;
  background-color: ${themeGet('colors.themeColor')};
  ${device.small} {
    padding-top: 80px;
    padding-bottom: 80px;
  }
`;

export const InstagramWrap = styled.div`
  display: flex;
  flex-wrap: wrap;
  margin: -5px;
  justify-content: center;
`;

export const InstagramItem = styled.div`
  flex-basis: 100%;
  max-width: 233px;
  height: 100%;
  max-height: 233px;
  overflow: hidden;
  margin: 5px;
  border-radius: 8px;

  ${device.xxsmall} {
    flex-basis: 50%;
    max-width: 100px;
    max-height: 100px;
  }

  ${device.small} {
    flex-basis: 33.3333%;
    max-width: 140px;
    max-height: 140px;
  }

  ${device.medium} {
    flex-basis: 20%;
    max-width: 180px;
    max-height: 180px;
    height: 180px;
  }

  img {
    border-radius: 8px;
  }
  a {
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    text-indent: -999999px;
  }
`;
