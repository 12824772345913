import React, { useEffect, useState } from 'react';
import { FiInstagram } from 'react-icons/fi';
import { Col, Container, Row } from 'reactstrap';
import Heading from '../../../components/shared/heading';
import Text from '../../../components/shared/text';
import { getInstaPictures } from '../../../utils/instagram';
import { InstagramItem, InstagramWrap, SectionWrap } from './instagram.stc';

const Instagram = ({ headingCSS, textCSS }) => {
  const [photos, setPhotos] = useState([]);

  useEffect(() => {
    getInstaPictures().then(instaPictures => {
      setPhotos(instaPictures.slice(0, 6));
    });
  }, []);

  return (
    <SectionWrap>
      <Container>
        <Row>
          <Col lg={12}>
            <Heading {...headingCSS}>
              <FiInstagram />
              <Text {...textCSS}>Ja na Instagramie</Text>
            </Heading>
          </Col>
        </Row>
        <Row>
          <Col lg={12}>
            <InstagramWrap>
              {photos &&
                photos.map((photo, i) => {
                  return (
                    <InstagramItem key={`instagram-${i}`}>
                      <img src={photo} alt="Instagram" />
                    </InstagramItem>
                  );
                })}
            </InstagramWrap>
          </Col>
        </Row>
      </Container>
    </SectionWrap>
  );
};

Instagram.defaultProps = {
  headingCSS: {
    as: 'h5',
    textTransform: 'uppercase',
    textAlign: 'center',
    lineHeight: 1,
    mb: '40px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  },
  textCSS: {
    as: 'span',
    pl: '4px'
  }
};

export default Instagram;
