import axios from 'axios';
import moment from 'moment';

const extractInstaPictures = response => {
  let instaPictures = [];

  if (response.data && response.data.graphql) {
    const { user } = response.data.graphql;
    const { edges } = user.edge_owner_to_timeline_media;

    instaPictures = edges
      .sort((edge1, edge2) => {
        const date1 = edge1.node.taken_at_timestamp;
        const date2 = edge2.node.taken_at_timestamp;

        return date2 - date1;
      })
      .map(edge => edge.node.display_url);

    const storageObject = {
      datetime: moment(),
      pictures: instaPictures
    };

    window.localStorage.setItem('instaPictures', JSON.stringify(storageObject));
  }

  return instaPictures;
};

export const getInstaPictures = () => {
  const storedPicturesString = window.localStorage.getItem('instaPictures');

  if (storedPicturesString) {
    const storedPicturesObject = JSON.parse(storedPicturesString);
    const storedTime = storedPicturesObject.datetime;
    const timeDifference = moment().diff(storedTime, 'minutes');
    const storedPictures = storedPicturesObject.pictures;

    if (timeDifference < 60) {
      return new Promise(resolve => {
        resolve(storedPictures);
      });
    }
  }

  return axios
    .get('https://www.instagram.com/logopedagadula.londyn/?__a=1')
    .then(response => extractInstaPictures(response));
};
